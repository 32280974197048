import React from 'react';
import PlayerWrapper from './components/PlayerWrapper'
import './styles/main.scss';
import 'what-input';

function App() {
  return (
    <div className="App">
      <PlayerWrapper />
    </div>
  );
}

export default App;
