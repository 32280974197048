import React from 'react';
import PropTypes from 'prop-types';

import Player from './Player';
import BottomInfo from './BottomInfo';

import '../styles/spinner.scss';

import Logo from '../images/humbly-logo.svg';

class PlayerWrapper extends React.Component {
  constructor() {
    super();
    this.id = window.location.pathname.split('/')[1];
    this.state = {
      isLoading: true,
      meta: null
    }
  }

  componentDidMount() {
    fetch('https://us-central1-humbly-app-7410e.cloudfunctions.net/shareMeta', {
      method: 'POST', // or 'PUT'
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({share_id: this.id})
    })
    .then(response => response.json())
    .then(metaData => {
      this.setState({
        isLoading: false,
        meta: metaData
      })
    })
  }

  render() {
    const { isLoading, meta } = this.state;
    return (
      <div>
        <div className="light-overlay"></div>
        <img src={Logo} alt="Humbly" className="main-logo" />

        {isLoading ?
          <div className="lds-roller mod-firstLoad"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        :
          <div>
            <Player
              meta={meta}
              isLoading={isLoading}
            />
          </div>
        }
        <BottomInfo />
      </div>
    )
  }
};

PlayerWrapper.propTypes = {
  audio_source: PropTypes.string,
  time: PropTypes.number,
  artwork_source: PropTypes.string
}

export default PlayerWrapper;
// export default addUrlProps({ urlPropsQueryConfig })(PlayerWrapper);