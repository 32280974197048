import React from 'react';

import '../styles/bottom.scss';
import Logo from '../images/humbly-logo.svg';
import BigH from '../images/big-h.svg';
import AppStore from '../images/app-store-button.svg';
import Dots from '../images/dots.svg';
import Close from '../images/x.svg';

class BottomInfo extends React.Component {
  constructor() {
    super();

    this.state = {
      isSmall: null,
      isFullHeight: false
    }

    this.appStoreClickHandler = this.appStoreClickHandler.bind(this);
    this.bottomClickHandler = this.bottomClickHandler.bind(this);
  }

  appStoreClickHandler = (e) => {
    e.stopPropagation();
  }

  bottomClickHandler = () => {
    this.setState(prevState => ({
      isFullHeight: !prevState.isFullHeight
    }));
  }

  render() {
    const body = document.querySelector('body');
    if (this.state.isFullHeight) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
    return (
      <div
        className={`bottom ${this.state.isFullHeight ? 'bottom-full' : ''}`}
      >
        <div className="bottom-small-tall">
          <button className="bottom-close" onClick={this.bottomClickHandler}>
            <img src={Close} className="bottom-small-tall-close" alt="Close"/>
          </button>
          <div>
            <img src={BigH} className="logo-big-h" alt="Big H logo" />
            <img src={Logo} className="bottom-small-logo" alt="Humbly" />
            <h1>The podcast app that raises money for nonprofits and charities</h1>
          </div>
          <div className="bottom-small-tall-app-store">
            <div>
              <a href="https://apps.apple.com/app/id1521804753" target="_blank" onClick={this.appStoreClickHandler}>
                <img className="bottom-app-store" src={AppStore} alt="Download for iOS" />
              </a>
            </div>
          </div>
          <div>
            <h2>How does it work?</h2>
            <ol>
              <li>Download Humbly from the App Store</li>
              <li>Pick charities and causes you care about</li>
              <li>Before and after podcasts, Humbly will play a short commerical or two</li>
              <li>Money goes to those charities and causes you picked</li>
            </ol>
          </div>
        </div>

        <div className="bottom-small-short" onClick={this.bottomClickHandler}>
          <img src={Dots} className="bottom-small-item bottom-small-dots" alt="Humbly" />
          <img src={Logo} className="bottom-small-item bottom-small-logo" alt="Humbly" />
          <a href="https://apps.apple.com/app/id1521804753" target="_blank" className="bottom-small-item" onClick={this.appStoreClickHandler}>
            <img src={AppStore} alt="Download for iOS" />
          </a>
        </div>
      </div>
    );
  }
}

export default BottomInfo;