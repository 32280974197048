import React from 'react';
import PropTypes from 'prop-types';

import Clipboard from 'react-clipboard.js';

import '../styles/slider.scss';
import '../styles/player.scss';

import Arrow from '../images/arrow.svg';
import PlayingBG from '../images/playback-playing.svg';
import PlaySymbol from '../images/playback-symbol-play.svg';
import PausedBG from '../images/playback-paused.svg';
import PauseSymbol from '../images/playback-symbol-pause.svg';
import ShareSymbol from '../images/share.svg';

function secondsToHms(d) {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);
  let hour = h;
  let minute = m;

  let hDisplay = h > 0 ? h : "";
  let mDisplay = m > 0 ? m : "00:";
  let sDisplay = s > 0 ? s : "00";

  if (hDisplay > 0) hDisplay = hDisplay + ":";
  if (hDisplay >= 10) hDisplay = hDisplay + ":";
  if (hDisplay === 0) hDisplay = "";

  if (mDisplay > 0) mDisplay = mDisplay + ":";
  if (mDisplay >= 10) mDisplay = mDisplay + ":";
  if (mDisplay === 0) mDisplay = "";
  if ((hour > 0) && (minute < 10)) mDisplay = "0" + mDisplay;

  if ((sDisplay < 10) && (sDisplay > 0)) sDisplay = "0" + sDisplay;

  return `${hDisplay}${mDisplay}${sDisplay}`;
}
class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: null,
      isBeingScrubbed: false,
      progress: null,
      progressFloat: null,
      spot: props.meta.timecode ? props.meta.timecode : '0',
      position: props.meta.timecode ? secondsToHms(props.meta.timecode) : secondsToHms(0),
      remaining: null,
      isBuffering: false,
      isPlaying: false,
      currentSec: null,
      suspendedPosition: null,
      suspendedProgress: null,
      suspendedRemaining: null,
      suspendedProgressFloat: null,
      fullTitles: false,
      didCopy: false
    }
    this.handleScrub = this.handleScrub.bind(this);
    this.handlePlayPause = this.handlePlayPause.bind(this);
    this.handleForwardBack = this.handleForwardBack.bind(this);
    this.playPause = React.createRef();
    this.title = 'Humbly · ' + props.meta.podcast_name + ' · ' + props.meta.episode_title;
    document.title = this.title;
  }

  setProgress = () => {
    const { duration } = this.state;
    const sliderInputValue = this.sliderInput.value;
    this.setState({
      progress: Math.round((sliderInputValue / duration) * 100),
      progressFloat: (sliderInputValue / duration) * 100,
      position: secondsToHms(parseInt(sliderInputValue, 10)),
      remaining: secondsToHms(parseInt(duration - sliderInputValue, 10)),
      currentSec: parseInt(sliderInputValue, 10),
      suspendedPosition: secondsToHms(parseInt(sliderInputValue, 10)),
      suspendedProgress: Math.round((sliderInputValue / duration) * 100),
      suspendedRemaining: secondsToHms(parseInt(duration - sliderInputValue, 10)),
      suspendedProgressFloat: (sliderInputValue / duration) * 100
    });
  }

  handleScrub(isReleased) {
    this.setState(prevState => ({
      isBeingScrubbed: !prevState.isBeingScrubbed,
      suspendedPosition: this.state.position
    }));
    if (isReleased) {
      this.player.currentTime = this.sliderInput.value;
      this.setState({
        spot: parseInt(this.sliderInput.value, 10),
        suspendedProgressFloat: (this.player.currentTime / this.state.duration) * 100
      })
    }
  }

  handleTopClick = () => {
    this.setState(prevState => ({
      fullTitles: !prevState.fullTitles
    }))
  }

  handlePlayPause = () => {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.player.pause();
      clearInterval(this.poller);
    }
    if (!isPlaying) {
      this.player.play();
      this.trackKeeper();
    }
    this.setState(prevState => ({
      isPlaying: !prevState.isPlaying
    }))
  }

  handleForwardBack(num) {
    const currentSpot = this.player.currentTime;
    this.player.currentTime = currentSpot + num
  }

  trackKeeper = () => {
    const slider = document.getElementById('slider-input');
    this.poller = setInterval(() => {
      if (this.player.readyState < 3) {
        this.setState({
          isBuffering: true,
          isPlaying: false
        })
      } else {
        this.setState({
          isBuffering: false,
          isPlaying: true
        })
      }
      const currentTime = Math.round(this.player.currentTime);
      if (this.player.ended) {
        clearInterval(this.poller);
        this.setState({
          isPlaying: false
        })
      }

      if (!this.state.isBeingScrubbed) {
        slider.value = currentTime;
      }

      this.setState({
        position: secondsToHms(currentTime),
        currentSec: currentTime,
        progressFloat: (this.player.currentTime / this.state.duration) * 100,
      });
    }, 300);
  }

  componentDidMount() {
    this.sliderInput = document.getElementById('slider-input');
    this.player = document.getElementById('player-audio-source');
    this.player.currentTime = this.state.spot;
    this.player.addEventListener('canplay', () => {
      this.setState({
        duration: this.player.duration,
      });
    })
  }

  render() {
    const {
      audio_source: audioSource,
      artwork_url: artworkSource,
      podcast_name: showName,
      episode_title: episodeTitle,
      link_id: linkId
    } = this.props.meta;

    const {
      isBeingScrubbed,
      position,
      progressFloat,
      spot,
      isPlaying,
      duration,
      suspendedPosition,
      suspendedProgress,
      suspendedRemaining,
      suspendedProgressFloat,
      fullTitles,
      didCopy
    } = this.state;


    const whatInput = document.getElementsByTagName('html')[0].dataset.whatinput;
    const theBody = document.getElementsByTagName('body')[0];
    if ((whatInput === 'mouse') || (whatInput === 'touch')) {
      theBody.classList.add('mouse-user');
    } else {
      theBody.classList.remove('mouse-user');
    }

    return (
      <div>
        <div className={`player ${isBeingScrubbed ? 'mod-scrubbing-layout' : ''}`}>
          <div className={`player-top ${fullTitles ? 'mod-full-titles' : ''}`} onClick={this.handleTopClick}>
            <div className="player-scrub-progress">{suspendedPosition}</div>
            <div className="player-scrub-otherinfo">
              <div>
                <span>PROGRESS</span>
                {suspendedProgress}%
              </div>
              <div>
                <span>REMAINING</span>
                {suspendedRemaining}
              </div>
            </div>
            <div className="player-artwork">
              <img src={artworkSource} alt="episode artwork" />
            </div>
            <div className={`player-buffering ${this.state.isBuffering ? 'mod-spinner-showing' : ''}`}>
              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
            <div className="player-meta">
              <div>
                <h1>{showName}</h1>
                <h2>{episodeTitle}</h2>
              </div>
            </div>
          </div>

          <div className={`slider ${isBeingScrubbed ? 'mod-scrubbing-slider' : ''}`} >
            <div className="slider-progress" style={{width: `${isBeingScrubbed ? suspendedProgressFloat : progressFloat}%`}}></div>
            <div className="slider-progress-under"></div>
            <input
              id="slider-input"
              className={`slider-input ${duration ? '' : 'mod-hidden'}`}
              type="range"
              min="0"
              max={duration}
              step="1"
              defaultValue={spot}
              onChange={this.setProgress}
              onMouseDown={() => {this.handleScrub()}}
              onMouseUp={() => {this.handleScrub(true)}}
              onTouchStart={() => {this.handleScrub()}}
              onTouchEnd={() => {this.handleScrub(true)}}
            />
          </div>

          <div className="player-bottom">
            <audio id="player-audio-source" src={audioSource}></audio>
            <div className="player-position">{position}</div>
            <div className="player-controls">
              <button className="player-controls-back" onClick={() => this.handleForwardBack(-10)}>
                <img src={Arrow} alt="arrow" /> 10
              </button>
              <button
                ref={this.playPause}
                className="player-controls-playpause"
                onClick={this.handlePlayPause}
              >
                <img
                  src={isPlaying ? PlayingBG : PausedBG}
                  className={`player-controls-playpause-button ${isPlaying ? 'mod-spinning' : ''}`}
                  alt="Play pause button"
                />
                <img
                  className={`player-controls-playpause-symbol ${isPlaying ? 'mod-pause' : 'mod-play'}`}
                  src={isPlaying ? PauseSymbol : PlaySymbol}
                  alt={isPlaying ? "Play symbol" : "Pause symbol"}
                />
              </button>
              <button className="player-controls-forward" onClick={() => this.handleForwardBack(20)}>
                20 <img src={Arrow} alt="arrow" />
              </button>
            </div>
            <Clipboard
              className="player-share"
              data-clipboard-text={linkId}
              button-title="Copy share link to clipboard"
              onSuccess={() => {this.setState({didCopy: true})}}
            >
              <img src={ShareSymbol} alt="Share" />
              <div className={`player-share-notice ${didCopy ? 'mod-notice-showing': ''}`}>COPIED TO CLIPBOARD</div>
            </Clipboard>
          </div>
        </div>
      </div>
    )
  }
};

Player.propTypes = {
  audioSource: PropTypes.string,
  artworkSource: PropTypes.string,
  time: PropTypes.number,
}

export default Player;